<template>
  <el-main id="login-page" class="login-container">
    <div class="inner-container">
      <img :src="Logo" alt="PMO" />
      <div class="google-login" id="login-button">
        <LoginGoogle @login="onSucess" :clientId="clientId" />
      </div>
      <p class="google-login-error" v-if="error">{{ error }}</p>
    </div>
  </el-main>
</template>

<script>
import { mapActions } from 'vuex'
import { getGoogleUserDetails } from '@/service/googleapis'
import { auth } from '@/service/accessLevel'
import Logo from '/public/assets/PMO_Login.svg'
import LoginGoogle from '@/components/LoginGoogle/LoginGoogle.vue'

export default {
  name: 'Login',
  data() {
    return {
      error: false,
      Logo: Logo,
      clientId: process.env.VUE_APP_CLIENT_ID,
    }
  },
  components: {
    LoginGoogle,
  },
  methods: {
    ...mapActions(['doLogin', 'doToken']),

    async onSucess({ credential: token }) {
      let loading = this.$loading({
        lock: true,
        text: 'Entrando...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const googleInfo = await getGoogleUserDetails(token)
        await this.doToken(token)

        const { data } = await auth(token)
        if (data.user.email) {
          this.doToken(token)
          this.doLogin({
            accessLevel: data.user.accessLevel,
            userName: googleInfo.name,
            userEmail: data.user.userEmail,
            userCostCenters: data.user.costCenters,
          })
          this.$message({
            showClose: true,
            type: 'success',
            sshowClose: true,
            message: 'Login realizado com sucesso!',
          })
          this.$router.push('/')
        }
      } catch (error) {
        this.error = 'Usuário não autorizado!'
        this.$message({
          showClose: true,
          type: 'error',
          sshowClose: true,
          message: 'Usuário não autorizado!',
        })
      } finally {
        loading.close()
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>

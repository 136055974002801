<template>
  <div>
    <div id="signin_button"></div>
  </div>
</template>

<script>
export default {
  name: 'LoginGoogle',
  props: {
    clientId: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    handleCredentialResponse(response) {
      this.$emit('login', response)
    },
  },
  mounted() {
    let googleScript = document.createElement('script')
    googleScript.src = 'https://accounts.google.com/gsi/client'
    googleScript.async = true
    document.head.appendChild(googleScript)

    window.addEventListener('load', () => {
      window.google.accounts.id.initialize({
        client_id: this.clientId,
        callback: this.handleCredentialResponse,
      })
      window.google.accounts.id.renderButton(
        document.getElementById('signin_button'),
        { theme: 'outline', size: 'large' }
      )
    })
  },
}
</script>
